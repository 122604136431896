@import url('https://fonts.googleapis.com/css?family=Inria+Sans&display=swap');

.body {
    font-family: 'Inria Sans', sans-serif;
    color: #333;
    background-color: #E7625F;
}

nav {
    background-color: #E7625F;
    padding: 1em 0;
    position: fixed;
    width: 100%;
    border-bottom: 1px solid #FFFFFF;
}

nav .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1140px;
    margin: 0 auto;
    padding: 0 1em;
}

nav .logo-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 5px;
}
nav .logo {
    color: #fff;
    margin: 0;
    font-size: 1.5em;
}

nav ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}

nav li {
    margin: 0 1em;
}

nav a {
    color: #fff;
    text-decoration: none;
    font-size: 1.2em;
    font-weight: bold;
}

nav .cta {
    background-color: #FFFFFF;
    color: #E7625F;
    padding: 0.5em 1em;
    border-radius: 0.75em;
    font-size: 0.9em;
    font-weight: bold;
    transition: all 0.3s ease;
}

nav .cta:hover {
    border-radius: 1.5em;
    background-color: #E7625F;
    color: #fff;
    cursor: pointer;
}



header {
    background-color: #E7625F;
    padding: 5em 0;
    text-align: center;
}

header .container {
    margin-top: 100px;
    margin-left: 10vw;
    margin-right: 10vw;
    background-color: #E7625F;
}

header .container a {
    text-decoration: none;
    font-size: 1.2em;
    font-weight: bold; 
}

header .sub {
    margin-top: 20px;
}
header .sub p {
    margin: 8px;
}

.overview .grid-img {
    border-radius: 15px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.overview h2 {
    font-size: 3em;
    font-weight: 600;
    color: white;
    text-align: center;
    margin-bottom: 40px;
}


/* Styles for desktop */
@media screen and (min-width: 768px) {
    .nav-cta-container .cta {
        margin-left: 20px;
    }
    header .container h1 {
        font-size: 7em;
    }

    header .sub p {
        font-size: 2em;
    }
    .overview .grid-img {
        width: 30vw;
        margin: auto;
    }
    .overview .grid-img-horizontal {
        width: 65vw;
        margin: auto;
    }
}
  
/* Styles for mobile */
@media screen and (max-width: 767px) {
    .nav-cta-container .cta {
        margin-left: 10px;
    }

    header .container h1 {
        font-size: 2.5em;
    }

    nav ul {
        display: none;
    }

    header .sub p {
        font-size: 1.5em;
    }
    .overview .grid-img {
        width: auto;
        margin-left: 5vw;
        margin-right: 5vw;
    }

    .overview h2 {
        font-size: 2em;
    }
}


.highlight {
    color: #FFFFFF;
}


header h1 {
    margin: 0;
    font-size: 3em;
    font-weight: bold;
    color: white;
}

header p {
    margin: 1.5em 0;
    font-size: 1.2em;
    line-height: 1.5;
    color: white;
}

.main-cta {
    background-color: #FFFFFF;
    color: #E7625F;
    border: 2px solid #E7625F;
    padding: .75em 1em;
    border-radius: 0.5em;
    font-size: 0.9em;
    font-weight: bold;
    transition: all 0.3s ease;
    margin-top: 2em;
    display: inline-block;
}

.main-cta:hover {
    background-color: #FFFFFF;
    color: #E7625F;
    cursor: pointer;
    border-radius: 2em;
}

.overview {
    background-color: #E7625F;
    padding: 80px 0;
}

.overview .container {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
}

.overview .container .section {
    display: flex;
    flex-direction: column;
    margin-bottom: 100px;
    
}



.features {
    background-color: #fff;
    padding: 80px 0;
}

.features h2 {
    font-size: 36px;
    font-weight: 600;
    color: #333;
    text-align: center;
    margin-bottom: 40px;
}

.features ul {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: center;
}

.features li {
    font-size: 18px;
    color: #333;
    margin-bottom: 20px;
    display: inline-block;
    width: 100%;
    text-align: left;
    padding-left: 20px;
}

.testimonials {
    background-color: #E7625F;
    color: white;
    padding: 80px 0;
}

.testimonials h2 {
    font-size: 36px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 40px;
}

.testimonials blockquote {
    font-size: 18px;
    text-align: center;
    margin-bottom: 40px;
    line-height: 1.5;
}

.testimonials cite {
    font-size: 18px;
    display: block;
    text-align: center;
}

footer {
    background-color: #333;
    color: #fff;
    padding: 40px 0;
    text-align: center;
}

footer p {
    margin: 0;
    font-size: 18px;
}
